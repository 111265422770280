.ride-merger-container {
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 0.1fr 1fr;
    height: 100%;
}

.ride-merger-order-tile {
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #ddd;
    margin: 1%;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    width: 600px;
    max-height: 185px;
    min-height: 155px;
    grid-template-columns: 0.5fr 5fr 1.5fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
}

.ride-merger-order-tile-draggable {
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #ddd;
    margin: 1%;
    width: 600px;
    max-height: 185px;
    min-height: 155px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 0.5fr 5fr 1.5fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-gap: 1px;
}

.ride-merger-order-tile-draggable:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 24px 25px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    width: 590px;
}

.ride-merger-route-tile {
    padding: 2%;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #ddd;
    margin: 1%;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    grid-gap: 15px;
    display: grid;
    grid-template-columns: 0.1fr 1fr 0.1fr;
}

.ride-merger-route-tile-orders {
    margin-top: -150%;
    transition: all 0.5s;
}

.ride-merger-route-tile-orders-wrapper {
    overflow: hidden;
}

.ride-merger-order-tile > span {
    display: grid;
    margin: auto;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr 0.1fr;
    width: 100%;
}

.ride-merger-filterable-column .scroll {
    height: 65vh;
    overflow: auto;
    border-radius: 10px;
    border: 1px solid #ddd;
}

.ride-merger-filterable-column .header {
    text-align: center;
    display: grid;
    max-height: 140px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}

.ride-merger-container .action-button-dark {
    border: 0 !important;
    font-family: "Roboto";
    font-size: 20px !important;
    font-weight: 400;
    width: 50%;
    color: white;
    height: 45px;
    border-radius: 30px !important;
    background-color: black;
    display: grid;
    margin: auto;
    cursor: pointer;
    margin-top: 20px;
  }

 .ride-merger-container .action-button {
    background-color: rgb(239, 191, 45);
    border: 0 !important;
    font-family: "Roboto";
    font-size: 20px !important;
    font-weight: 400;
    width: 50%;
    color: black;
    height: 45px;
    border-radius: 30px !important;
    display: grid;
    margin: auto;
    cursor: pointer;
    margin-top: 20px;
  }

  .ride-merger-drop-zone-active {
    background-color: #24b373;
  }

  .ride-merger-results-container {
    position: absolute;
    height: 0;
    width: 0;
    right: 0;
    top: 0;
    padding-top: 50px;
    padding-left: 110px;
    background-color: white;
    transition: height 0.3s, width 0.3s;
    -moz-transition: height 0.3s, width 0.3s;
    -webkit-transition: height 0.3s, width 0.3s; 
    -o-transition: height 0.3s, width 0.3s; 
    overflow: hidden;
  }

  .ride-merger-results-button {
    position: absolute;
    right: 35px;
    top: 80px;
    font-size: 1.2em;
    cursor: pointer;
    z-index: 2;
    font-weight: bold;
    color: rgb(150, 207, 235);
  }