.new-driver-submission-container {
  transition: ease 0.3s;
  height: 100%;
  display: flex;
  align-items: center; }
  .new-driver-submission-container div.input-wrapper {
    width: 500px;
    margin: auto;
    padding: 25px;
    border: 1px solid #f3f3f3;
    background: white;
    border-radius: 5px; }
    .new-driver-submission-container div.input-wrapper label.text-label {
      min-height: 24px;
      font-size: 16px;
      line-height: 1.5em;
      margin: 0 0 20px 0;
      font-weight: 400; }
    .new-driver-submission-container div.input-wrapper p.validation-error {
      min-height: 24px;
      font-size: 14px;
      line-height: 16px;
      margin: 5px 0 20px 0;
      color: #c9302c; }
    .new-driver-submission-container div.input-wrapper button {
      display: block;
      margin: auto;
      width: 100%; }
