

#loginForm{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.card{
    width: 100% !important;
}
