.pop-up-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  overflow: scroll;
  align-items: center; }
  .pop-up-container .modal {
    display: flex;
    margin: auto;
    min-width: 800px;
    min-height: 600px;
    width: 50%;
    height: 80%;
    background-color: white;
    border-style: solid;
    border-color: grey;
    border-width: 1px;
    text-align: center;
    max-width: 1000px;
    max-height: 800px;
    border-radius: 25px;
    padding-bottom: 20px; }
  .pop-up-container .modal_content {
    width: 100%;
    margin: 5%;
    display: flex;
    flex-wrap: column wrap; }
  .pop-up-container #cancelButtton {
    margin-left: 50px;
    margin-bottom: 25px;
    width: 25%; }
  .pop-up-container #confirmButton {
    float: right;
    margin-right: 50px;
    margin-bottom: 25px;
    width: 25%; }
  .pop-up-container #close {
    top: 0;
    float: right;
    font-size: larger; }
  .pop-up-container .nameInput {
    width: 45%; }
  .pop-up-container .formInput {
    width: 100% !important; }
  .pop-up-container .location-input-container {
    width: 100% !important; }
  .pop-up-container .rdt {
    width: 100%; }
  .pop-up-container .ride-choice {
    display: flex;
    justify-content: center; }
  .pop-up-container .nameInput {
    padding-left: 10px; }
  .pop-up-container .formInput {
    padding-left: 10px; }
  .pop-up-container #phoneNumber {
    width: 45% !important; }
  .pop-up-container #extension {
    width: 45% !important;
    margin-left: 10%; }
  .pop-up-container .react-datepicker {
    font-size: 1em; }
  .pop-up-container .react-datepicker__header {
    padding-top: 0.8em; }
  .pop-up-container .react-datepicker__month {
    margin: 0.4em 1em; }
  .pop-up-container .react-datepicker__day-name, .pop-up-container .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em; }
  .pop-up-container .react-datepicker__current-month {
    font-size: 1em;
    margin-bottom: 15px; }
  .pop-up-container .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
    margin-bottom: 15px; }
  .pop-up-container .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em; }
  .pop-up-container .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em; }
  .pop-up-container .react-datepicker-time__input {
    width: 100% !important; }
  .pop-up-container .react-datepicker-wrapper {
    width: 100% !important; }
  .pop-up-container .scheduledDate {
    width: 100%;
    padding-left: 15px;
    margin-bottom: 15px; }
  .pop-up-container .rightLabel {
    float: right;
    width: 45%; }
