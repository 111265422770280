.pop-up-container {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 14;
  overflow: scroll;
  align-items: center; }
  .pop-up-container .modal {
    display: flex;
    margin: auto;
    min-width: 800px;
    min-height: 600px;
    width: 50%;
    height: 80%;
    background-color: white;
    border-style: solid;
    border-color: grey;
    border-width: 1px;
    overflow: scroll;
    text-align: center;
    border-radius: 15px;
    padding: 0 10px; }
