/* STYLES FOR DASHBOARD GRAPHS */

.text-purple {
  color: #85188b;
}

.text-green {
  color: #80c206;
}

.text-blue {
  color: #447DF7;
}

.text-grey {
  color: #5e5e5e;
}

.text-orange {
  color: #DD4B39;
}

.text-darkblue {
  color: #35465C;
}

.text-darkred {
  color: #E52D27;
}