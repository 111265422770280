.food-types button {
    margin-top: 0 !important;
}

.new-edit-restaurant .has-feedback label~.form-control-feedback {
    top: 50%;
}

.new-edit-restaurant .restaurant-image-preview {
    margin-top: 10px;
}

.new-edit-restaurant .rbt-input-multi.focus {
    border: 1px solid #bdbdbd;
    box-shadow: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.new-edit-restaurant .rbt-token {
    /* color: rgba(235, 205, 34, 1); */
    /* color: black; */
    /* color: white; */
    color: #353535;
    background-color: rgba(235, 205, 34, 0.24);
    opacity: 1;
}

.new-edit-restaurant .rbt-token-active {
    background-color: rgba(235, 205, 34, 0.932);
    color: white;
}

.new-edit-restaurant .rbt-menu {
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    padding: 8px 12px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.new-edit-restaurant .rbt-menu a {
    display: block;
    color: #565656;
}

.new-edit-restaurant .rbt-menu a.active {
    background-color: rgba(235, 205, 34, 0.932);
    cursor: pointer;
    color: white;
    padding: 2px;
    border-radius: 4px;
}

.new-edit-restaurant .restaurant-cta-buttons {
    width: 100%;
}

.new-edit-restaurant .restaurant-cta-buttons button:nth-child(2) {
    float: right;
}

.location-input-restaurants .form-control-feedback{
    margin: 0 auto;
    top: 18% !important;
}