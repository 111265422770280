div.ana-tabs div.heads-container {
  padding-left: 10px; }

div.ana-tabs div.body-container {
  width: 100%;
  min-height: 300px;
  background-color: white;
  display: flex;
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1) inset;
  padding: 30px; }
