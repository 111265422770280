div.request-history {
  width: 100%; }
  div.request-history #table-title {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 30px 0 15px 0;
    padding: 0 5% 0 20px; }
    div.request-history #table-title h4 {
      flex-grow: 2;
      margin: 0; }
    div.request-history #table-title .legend-item {
      display: inline;
      margin-left: 25px; }
      div.request-history #table-title .legend-item p {
        display: inline;
        margin: 0 0 0 5px;
        font-size: 14px; }
  div.request-history table {
    width: 100%; }
    div.request-history table tr.canceled {
      background-color: firebrick;
      color: white; }
    div.request-history table tr.accepted {
      background-color: green;
      color: white; }
    div.request-history table .right-separator {
      border-right: 2px solid lightgrey; }
    div.request-history table thead th {
      text-align: center;
      height: 3em;
      font-weight: bold;
      border-top: 2px solid lightgrey;
      border-bottom: 2px solid lightgrey;
      font-size: 1.1em; }
    div.request-history table tbody tr:nth-child(2n + 1) {
      background: #f7f7f7; }
    div.request-history table tbody td {
      min-width: 12.5%;
      text-align: center;
      height: 3em; }
  div.request-history #log p {
    height: 3em;
    width: 100%;
    margin: 0;
    padding-left: 2em;
    line-height: 3em;
    font-size: 15px;
    background: #fafafa; }
    div.request-history #log p.log-line-declined {
      background: rgba(245, 201, 6, 0.15); }
    div.request-history #log p.log-line-accepted, div.request-history #log p.log-line-finished {
      background: rgba(30, 231, 7, 0.15); }
    div.request-history #log p.log-line-canceled {
      background: rgba(228, 12, 12, 0.15); }
    div.request-history #log p.log-line-onsite, div.request-history #log p.log-line-started {
      background: rgba(3, 58, 240, 0.15); }
  div.request-history span.authority-icon {
    color: black;
    background-color: #c7c7c7;
    font-size: 13px;
    font-weight: 600;
    padding: 0.25em 0.5em;
    border-radius: 50%; }
