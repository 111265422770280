.info-icon {
  width: 17px;
  height: 17px;
  display: inline-block;
  position: relative; }
  .info-icon span {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 12;
    width: 100%;
    height: 100%;
    display: inline-block;
    background-color: #333;
    color: white;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border-radius: 50%;
    opacity: 0.9;
    cursor: default; }
  .info-icon p {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 11;
    width: 300px;
    background: webkit-gradient(circle 12px at calc(17px / 2) calc(17px / 2), transparent 11px, rgba(51, 51, 51, 0.9) 12px);
    background: radial-gradient(circle 12px at calc(17px / 2) calc(17px / 2), transparent 11px, rgba(51, 51, 51, 0.9) 12px);
    color: white;
    font-size: 14px;
    opacity: 0;
    padding: 20px 1em;
    text-align: center;
    border-radius: 3px;
    display: none; }
  .info-icon:hover p {
    display: block; }
  .info-icon:hover span, .info-icon:hover p {
    opacity: 1; }
