div.subform-subsection {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, calc((100% / 3) - (100px / 3)));
  grid-column-gap: 50px; }
  div.subform-subsection h3 {
    grid-column-start: 1;
    grid-column-end: 4;
    display: block;
    font-size: 22px;
    line-height: 1em;
    margin-bottom: 1.5em; }
  div.subform-subsection div.form-group {
    margin-bottom: 20px; }
