.table-api-subtable-container tr.step-done td.step-type {
  background-color: #90EE90; }

.table-api-subtable-container tr.current-step td.step-type {
  background-color: #ffeba1;
  margin: 0,0,0,-15px;
  margin-left: -15px; }

div.rt-td.rt-expandable {
  margin: 0; }
