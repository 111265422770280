div.ana-tab-head {
  display: inline-block;
  position: relative;
  margin-left: 1em; }
  div.ana-tab-head:hover {
    cursor: pointer;
    color: black; }
  div.ana-tab-head > div:first-child {
    padding: 10px 20px 15px 20px; }
  div.ana-tab-head div.tab-selected {
    width: 100%;
    color: black; }
  div.ana-tab-head p {
    margin: 0; }
  div.ana-tab-head div.bottom-line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    border-radius: 5px 5px 0 0;
    background: orange; }
