.document {
  z-index: 14; }
  .document .pop-up-container {
    z-index: 14; }
    .document .pop-up-container .modal {
      flex-direction: column;
      padding: 0;
      border-radius: 6px;
      background-color: #e6e6e6;
      height: 90%; }
      .document .pop-up-container .modal .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px;
        padding-left: 20px; }
        .document .pop-up-container .modal .header p {
          margin: 0;
          font-weight: bold; }
      .document .pop-up-container .modal .content {
        overflow: scroll;
        border: 3px;
        border-top: 0px;
        padding: 0px 10px 10px 10px; }
        .document .pop-up-container .modal .content img {
          width: 100%;
          object-fit: contain; }
    .document .pop-up-container .exit-button {
      cursor: pointer;
      width: 20px;
      height: 20px;
      padding: 0;
      margin: 0;
      z-index: 15;
      background-color: transparent;
      border: none; }
      .document .pop-up-container .exit-button img {
        width: 100%;
        height: 100%; }
