.progress-ring-wrapper {
    position: relative;
}

.progress-ring-wrapper>span {
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
}

.progress-ring-wrapper .progress-ring {
    position: absolute;
}

.progress-ring-wrapper .progress-ring .progress-ring-circle {
    transition: stroke-dashoffset 0.35s;
    transform-origin: 50% 50%;
    transform: rotate(-90deg);
}