.col-d {
    padding: 30px;
    text-align: center;
}

.setting-title {
    font-size: 1.8em;
}

.setting-subtitle {
    font-size: 1em;
    color: #888;
}

.checkbox-d {
    cursor: pointer;
}

.checkbox-d-label {
    margin-left: 10px;
}