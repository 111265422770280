.manualDispatch .pop-up-container .modal {
  max-height: 300px !important;
  min-width: 450px !important;
  min-height: 250px !important;
  border-radius: 25px;
  overflow: hidden !important; }
  .manualDispatch .pop-up-container .modal .modal_content {
    width: 100%;
    display: block !important; }
  .manualDispatch .pop-up-container .modal .formInput {
    width: 100% !important; }
  .manualDispatch .pop-up-container .modal .form-group {
    text-align: left; }
  .manualDispatch .pop-up-container .modal .control-label {
    padding-left: 5px; }
  .manualDispatch .pop-up-container .modal .buttonContainer {
    width: 100%;
    display: flex !important;
    justify-content: space-around !important; }
  .manualDispatch .pop-up-container .modal #cancelButtton {
    margin-bottom: 25px;
    margin-left: 0px !important;
    width: 35%; }
  .manualDispatch .pop-up-container .modal #confirmButton {
    margin-bottom: 25px;
    width: 35%;
    margin-right: 0px !important; }
