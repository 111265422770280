div.ride-request-summary h1 {
  font-size: 24px;
  margin: 0 0 0 20px; }

div.ride-request-summary h2 {
  font-size: 18px;
  margin: 0 0 0 20px; }

div.ride-request-summary section.header {
  margin: 20px 0 40px 0; }
