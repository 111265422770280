.edit-driver-submission-container section.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 150px; }
  .edit-driver-submission-container section.header .progress-ring-wrapper {
    display: block;
    width: 110px;
    min-width: 110px;
    margin: 0 10px; }
  .edit-driver-submission-container section.header div.left {
    width: calc(65% - 55px);
    position: relative; }
    .edit-driver-submission-container section.header div.left h1,
    .edit-driver-submission-container section.header div.left h2,
    .edit-driver-submission-container section.header div.left h3 {
      margin: 0; }
    .edit-driver-submission-container section.header div.left h1 {
      display: inline-block;
      font-size: 40px;
      line-height: 40px;
      font-weight: 400;
      margin-bottom: 0.15em; }
    .edit-driver-submission-container section.header div.left h2 {
      font-size: 20px;
      line-height: 20px;
      width: 100%; }
    .edit-driver-submission-container section.header div.left h3 {
      display: inline-block;
      position: relative;
      top: -6px;
      margin-left: 1.5em;
      font-size: 17px;
      line-height: 17px;
      border: 2px solid #e6e6e6;
      border-radius: 5px;
      padding: 4px; }
  .edit-driver-submission-container section.header div.right {
    width: calc(35% - 55px);
    display: flex;
    justify-content: center;
    align-items: center; }
    .edit-driver-submission-container section.header div.right button {
      font-weight: bold;
      margin: 0;
      height: 50px; }
    .edit-driver-submission-container section.header div.right button:nth-child(1) {
      margin-right: 1em; }

.edit-driver-submission-container p.fetching-error-box {
  width: 100%;
  height: 300px;
  line-height: 300px;
  text-align: center;
  color: #c9302c;
  font-size: 18px; }

.edit-driver-submission-container button {
  font-weight: bold; }

.edit-driver-submission-container div.subform-vehicle-card,
.edit-driver-submission-container div.subform-driver-card {
  width: 100%; }

.edit-driver-submission-container div.subform-vehicle-card {
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  position: relative;
  padding-top: 64px;
  margin-bottom: 30px; }
  .edit-driver-submission-container div.subform-vehicle-card div.head {
    background: #ffeba1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 64px;
    padding: 15px 20px;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center; }
    .edit-driver-submission-container div.subform-vehicle-card div.head > h2 {
      height: 100%;
      font-size: 24px;
      line-height: 24px;
      margin: 0;
      font-weight: 500; }
    .edit-driver-submission-container div.subform-vehicle-card div.head > button:nth-of-type(1) {
      margin: 0 0 0 50px; }
    .edit-driver-submission-container div.subform-vehicle-card div.head > img:nth-of-type(1) {
      width: 32px;
      margin: 0 0 0 auto;
      line-height: 0;
      padding: 10px;
      background: transparent;
      border: none;
      transition: 0.3s ease-out;
      transform-origin: center;
      transform: rotateZ(0);
      cursor: pointer; }
    .edit-driver-submission-container div.subform-vehicle-card div.head > img:nth-of-type(1).active {
      transform: rotateZ(-90deg); }
  .edit-driver-submission-container div.subform-vehicle-card div.body {
    margin: 30px;
    transition: 0.3s ease-out; }

.edit-driver-submission-container button.add-button {
  display: block;
  width: 100%;
  height: 64px;
  background: transparent;
  border: solid 1px #111;
  color: #111;
  font-size: 50px;
  line-height: 50px;
  font-weight: 400;
  margin: 0;
  border-radius: 10px;
  opacity: 0.9; }
  .edit-driver-submission-container button.add-button:hover {
    background: #fafafa;
    opacity: 1; }

.edit-driver-submission-container div.bottom-buttons-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 50px; }
  .edit-driver-submission-container div.bottom-buttons-container button:nth-of-type(1) {
    margin-right: 1em; }
