.schedule-container .container {
    width: 100%;
}

.schedule-container .open-close-time {
    font-weight: 600;
}

.schedule-container .working-hours-row {
    margin-bottom: 5px;
}

.schedule-editor .modal-body{
    display: flex;
    align-items: center;
}

.schedule-editor .days-container, .schedule-editor .hours-container {
    width: 50%;
}

.schedule-editor .hours-container p{
    margin-top: 10px;
}

.schedule-editor .hours-option-toggle-group{
    position: absolute;
    bottom: 0;
    display: block;
    margin-bottom: 20px;
}

.schedule-editor .hours-option-toggle-group .btn-default{
    margin-right: 5px;
}

.schedule-editor .days-toggle-group {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.schedule-editor .days-container .btn-default, .schedule-editor .hours-container .btn-default{
    border: 1px solid #444 !important;
    border-radius: 4px !important;
    margin-bottom: 5px;
}

.schedule-editor .btn.active {
    background-color: rgba(235, 205, 34, 0.932) !important;
}

.schedule-editor .days-toggle-group .btn:hover {
    background-color: white;
}