.dropdown {
  position: relative;
  display: inline-block;
  min-width: 200px;
  max-width: 400px;
  margin-bottom: 0.5rem;
  font-family: "Spartan","Robotica",Arial,sans-serif;
  font-size: 16px; }

.dropdown select {
  width: 100%;
  padding: 0.3rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #fff;
  color: #495057;
  font-size: 1.5rem;
  line-height: 1.5;
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  font-family: "Spartan","Robotica",Arial,sans-serif;
  font-weight: 400; }

.dropdown select:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
