div.ride-request-details {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  div.ride-request-details section {
    display: inline-block;
    width: 50%; }
    div.ride-request-details section h4 {
      margin-bottom: 1em; }
    div.ride-request-details section ul {
      list-style-type: none;
      font-size: 16px; }
      div.ride-request-details section ul dl {
        margin-bottom: 0.75em; }
        div.ride-request-details section ul dl dt {
          margin-right: 1em; }
          div.ride-request-details section ul dl dt:after {
            content: " : "; }
        div.ride-request-details section ul dl dt,
        div.ride-request-details section ul dl dd {
          display: inline; }
