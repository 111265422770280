.dashboard-report-container > * {
  min-height: initial; }

.dashboard-report-container .revealView {
  border: 1px solid red;
  min-height: 1000px; }
  .dashboard-report-container .revealView > * {
    position: static; }

.dashboard-report-container :first-child {
  left: 0 !important;
  width: 100% !important; }
