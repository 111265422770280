.comment-box {
    width: 100%;
    border: 1px solid rgb(240, 240, 240);
    border-radius: 10px;
}

.comment-box-wrapper {
    margin-bottom: 30px;
}

.comment-author-span {
    float: left;
}

.comment-edit-span {
    float: right;
}

.comment-edit-btn, .comment-delete-btn {
    cursor: pointer;
}

.comment-edit-btn:hover, .comment-delete-btn:hover{
    color: rgb(255, 138, 17);
    transition: 0.5s
}

.comment-box-header {
    padding: 5px 10px;
    background-color: rgb(246, 246, 246);
    overflow: hidden;
    letter-spacing: 1px;
}

.comment-box-ts {
    float: right;
    margin: 5px;
}

.comment-box-body {
    padding: 10px
}

.comment-box-edit-blur {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
}

.comment-box-edit-container {
    position: fixed;
    height: 40vh;
    width: 40vw;
    background-color: white;
    top: 20%;
    left: 30%;
    border-radius: 5px;
}

.comment-box-edit-text-area {
    margin: 2% 10%;
    width: 80%;
    height: 18vh;
    resize: none;
    padding: 1%;
}

.comment-box-edit-cancel-btn {
    float: right;
    margin-right: 2%;
    padding: 1%;
}

.comment-box-edit-submit-btn {
    margin-right: 10%;
    float: right;
    padding: 1%;
}