.eva-toast {
    position: absolute;
    width: 250px;
    min-height: 50px;
    border-radius: 50px;
    display: grid;
    top: 120px;
    right: 20px;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
}

.eva-toast-text {
    margin: auto;
    color: white;
    font-weight: bold;
}