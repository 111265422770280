div.morphing-field-group {
  transition: ease 0.3s; }
  div.morphing-field-group input[type="date"]:not(:disabled) {
    cursor: pointer; }
  div.morphing-field-group .label-wrapper {
    margin-bottom: 5px;
    display: flex;
    align-items: center; }
    div.morphing-field-group .label-wrapper label {
      margin-bottom: 0px;
      margin-right: 0.75em; }
  div.morphing-field-group div.checkboxes-container {
    height: 40px;
    padding: 0;
    line-height: 40px;
    display: flex;
    align-items: center; }
    div.morphing-field-group div.checkboxes-container input {
      width: 18px;
      height: 18px;
      margin: 0 7px 0 0;
      cursor: pointer; }
      div.morphing-field-group div.checkboxes-container input:disabled {
        cursor: not-allowed; }
    div.morphing-field-group div.checkboxes-container label {
      line-height: 18px;
      margin: 0 25px 0 0; }
      div.morphing-field-group div.checkboxes-container label.disabled {
        opacity: 0.6; }
  div.morphing-field-group div.tweaked-file-input {
    position: relative;
    width: 100%;
    height: 40px;
    border: solid 1px #e3e3e3;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: end; }
    div.morphing-field-group div.tweaked-file-input > * {
      height: 40px; }
    div.morphing-field-group div.tweaked-file-input p {
      position: absolute;
      left: 0;
      width: calc(100% - 80px);
      margin: 0;
      padding: 0 1em;
      font-weight: normal;
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    div.morphing-field-group div.tweaked-file-input input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
      padding: 0; }
    div.morphing-field-group div.tweaked-file-input button,
    div.morphing-field-group div.tweaked-file-input label {
      cursor: pointer;
      width: 40px;
      margin-left: 5px;
      border: none;
      border-radius: 4px;
      margin: 0;
      padding: 0;
      transition: ease 0.3s; }
      div.morphing-field-group div.tweaked-file-input button:hover:not(:disabled),
      div.morphing-field-group div.tweaked-file-input label:hover:not(:disabled) {
        background-color: #e3e3e3; }
      div.morphing-field-group div.tweaked-file-input button:disabled,
      div.morphing-field-group div.tweaked-file-input label:disabled {
        cursor: not-allowed;
        opacity: 0.5; }
    div.morphing-field-group div.tweaked-file-input button {
      background: url("../../../../../assets/img/eye_icon_black.png") 50% no-repeat;
      background-size: 30px; }
    div.morphing-field-group div.tweaked-file-input label {
      background: url("../../../../../assets/img/upload_arrow_yellow.png") 50% no-repeat;
      background-size: 25px;
      z-index: 10; }
  div.morphing-field-group p.validation-error {
    padding-top: 5px;
    min-height: 24px;
    font-size: 14px;
    line-height: 16px;
    margin: 0;
    color: #c9302c; }
  div.morphing-field-group div.form-group {
    margin-bottom: 20px; }
  div.morphing-field-group.validation-error input:not([type="checkbox"]),
  div.morphing-field-group.validation-error select,
  div.morphing-field-group.validation-error div.tweaked-file-input {
    border-color: #c9302c; }
  div.morphing-field-group .spinner {
    height: 40px;
    display: flex;
    align-items: center; }
