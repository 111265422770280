

.tr .subRow .currentStep .td{
    background-color: yellow;
}

.table .table-striped .table-bordered .table-hover{
    background-color: black;
} 

.table.table.table-striped{
    margin-left: 35px;
    width: calc(100%-35px);
}